import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

import { environment } from 'src/environments/environment';
import { ContaCorrente } from 'src/app/components/conta-corrente/conta-corrente.model';
import { ContaCorrenteResumo } from 'src/app/components/conta-corrente/conta-corrente-resumo.model';
import { BaseService } from 'src/app/services/base/base.service';
import { DropDownDealerFav } from 'src/app/interface/dropdown-dealer-fav.model';
import { Check } from 'src/app/interface/check';
import { ValidaPermissao } from 'src/app/interface/valida-permissao';


@Injectable()
export class ContaCorrenteService extends BaseService {
	
	headers = new HttpHeaders();
	check: Check;

	constructor(protected http: HttpClient, public datepipe: DatePipe) {
		super( http,  `${environment.apiURL}/api/v1/contaCorrente/`);
				
		this.headers = this.headers.append( 'Authorization', `Bearer ${localStorage.getItem('accessToken')}`);

	}

	getLancamentosByDealerDtMovto(validaPermissao: ValidaPermissao, codDealer: number, dtInicio: string, dtFinal: string, tipo: number) {
		const searchUrl = `${environment.apiURL}
			/api/v1/contaCorrente/getLancamentosByDealerDtMovto?codDealer=${codDealer}&dtInicio=${dtInicio}&dtFinal=${dtFinal}&tipo=${tipo}`;
		return this.http
			.post<ContaCorrente>(searchUrl, validaPermissao, { headers: this.headers})
			.pipe(take(1));
	}

	getResumoByDealerDtMovimento(validaPermissao: ValidaPermissao,  codDealer: number, dtMovto: string) {
		const searchUrl = `${environment.apiURL}/api/v1/contaCorrente/getResumoByDealerDtMovimento?codDealer=${codDealer}&dtMovto=${dtMovto}`;
		return this.http
			.post<ContaCorrenteResumo>(searchUrl, validaPermissao, { headers: this.headers})
			.pipe(take(1));
	}

	getDropDownDealersByGrupoDealer(validaPermissao: ValidaPermissao,  codDealer: number ) {
		const searchUrl = `${environment.apiURL}
			/api/v1/extratos/getDropDownDealersByGrupoDealer?codDealer=${codDealer}`;
		return this.http
			.post<DropDownDealerFav>(searchUrl, validaPermissao, { headers: this.headers})
			.pipe(take(1));
	}

}

