import { Component, OnInit } from '@angular/core';

@Component({
	//template: `<p>{{ "DEFAULT.NO_PERMISSION_DEALER'" | translate }}</p>`
	selector: 'no-access',
	templateUrl: './no-access.component.html',
	styleUrls: ['./no-access.component.scss']
})
export class NoAccessComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
