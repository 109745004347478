import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';

import { Grupo } from './grupo.model';
import { CrudService } from 'src/app/services/crud/crud-service';
import { PageOrder } from '../../../interface/pageOrder/page-order';
import { ValidaPermissao } from 'src/app/interface/valida-permissao';

@Injectable({
	providedIn: 'root',
})
export class GrupoService extends CrudService<Grupo> {
	
	headers = new HttpHeaders();

	constructor(protected http: HttpClient) {
		super(http, `${environment.apiURL}/api/v1/grupos/`);
		
		this.headers = this.headers.append( 'Authorization', `Bearer ${localStorage.getItem('accessToken')}`);

	}

	getSearch(validaPermissao: ValidaPermissao, 
		strSearch: string,
		pageOrder: PageOrder
	) {
		const searchUrl = `${environment.apiURL}/api/v1/grupos/findByNmGrpCContainingIgnoreCase?strSearch=
				${strSearch}&pageNo=${pageOrder.getPageNumber}&pageSize=${pageOrder.getPageSize}&fieldOrder=${pageOrder.getSortField}&sortOrder=${pageOrder.getSortOrder}`;
		return this.http.post<Grupo>(searchUrl, validaPermissao, { headers: this.headers})
						.pipe(take(1));
	}

}
