import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { PageOrder } from '../../../interface/pageOrder/page-order';
import { DealerService } from '../dealer/dealer.service';
import { LoginService } from 'src/app/oauth/login/login.service';
import { AppConstants } from 'src/app/utils/app-constants';

@Component({
	selector: 'seleciona-dealer',
	templateUrl: './seleciona-dealer.component.html',
	styleUrls: ['./seleciona-dealer.component.scss'],
})
export class SelecionaDealerComponent implements OnInit, OnDestroy {

	serviceDealerSearch: DealerService;

	colsDealer: any[];
	sortFieldDealer = 'dealerNameC';
	pageOrderDealer: PageOrder;
	displaySearhDealer = true;
	titleSearchDealer = 'DEFAULT.SEL_DEALER.TITLE';

	loading = true;
	msgsDlgNewReg = [];
	datasource = null;

	codDealerPk: number;
	dealerNameC: string;
	pageSize = 9;

	constructor(
		protected serviceDealer: DealerService,
		private router: Router,
		private loginService: LoginService
	) {
		this.serviceDealerSearch = serviceDealer;
	}

	ngOnInit() {
		this.clearRegData();

		this.pageOrderDealer = new PageOrder(0, this.pageSize, 'dealerNameC', AppConstants.ORDER_INDEX_ASCENDING);
		this.colsDealer = [
			{ field: 'codDealerPk', index: '', header: 'DEALER.CODIGO', mask: '' , alignText: 'center' },
			{ field: 'dealerNameC', index: '', header: 'DEALER.NOME', mask: '' , alignText: 'left'},
			{ field: 'statusDescC', index: 'statusC', header: 'DEFAULT.STATUS', mask: '' , alignText: 'center'},
		];
	}

	ngOnDestroy() {
		this.displaySearhDealer = false;
	}

	clearRegData() {
		this.codDealerPk = null;
		this.dealerNameC = null;
		this.loading = true;
		this.msgsDlgNewReg = [];
		this.datasource = null;
	}

	onSearchDealerRowSelect(event) {
		this.codDealerPk = event.value.codDealerPk;
		this.dealerNameC = event.value.dealerNameC;

		localStorage.setItem('codDn', event.value.codDealerPk);
		localStorage.setItem('nomeC', event.value.dealerNameC);

		this.loginService.dealerCod$.emit(event.value.codDealerPk);
		this.loginService.dealerName$.emit(event.value.dealerNameC);

		// vai para a raiz
		this.router.navigate(['/components/conta-corrente']);
		this.displaySearhDealer = false;
	}

	onChangeStatus(event) {

	}

}
