import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class DateService {
	/*
	let j = this.dateTimeService.dateNow();
	let k = this.dateTimeService.dateTimeNow();
	let l = this.dateTimeService.dateTimeStartNow();
	let m = this.dateTimeService.dateTimeEndNow();
	let n = this.dateTimeService.dateTimeStart( this.dtInicio );
	let o = this.dateTimeService.dateTimeEnd( this.dtInicio );
	*/

	constructor(public datepipe: DatePipe) { }

	timeNow() {
		return this.datepipe.transform(new Date(), 'HH:mm:ss');
	}

	dateNow() {
		return this.datepipe.transform(new Date(), 'dd/MM/yyyy');
	}

	dateTimeNow() {
		return this.datepipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss');
	}

	dateTimeStartNow() {
		const date = new Date().setHours(0, 0, 0, 0);
		return this.datepipe.transform(date, 'dd/MM/yyyy HH:mm:ss');
	}

	dateTimeEndNow() {
		const date = new Date().setHours(23, 59, 59, 0);
		return this.datepipe.transform(date, 'dd/MM/yyyy HH:mm:ss');
	}

	dateTimeStart(date: Date) {
		const dateTimeNow = date.setHours(0, 0, 0, 0);
		return this.datepipe.transform(dateTimeNow, 'dd/MM/yyyy HH:mm:ss');
	}

	dateTimeEnd(date: Date) {
		const dateTimeEnd = date.setHours(23, 59, 59, 0);
		return this.datepipe.transform(dateTimeEnd, 'dd/MM/yyyy HH:mm:ss');
	}

	dateTime(date: Date) {
		return this.datepipe.transform(date, 'dd/MM/yyyy HH:mm:ss');
	}

	date(date: Date) {
		return this.datepipe.transform(date, 'dd/MM/yyyy');
	}

	IsBusinessDay() {
		const date = new Date();
		return (date.getDay() > 1 || date.getDay() < 7);
	}

	dateTimeMask(date: Date, mask: string) {
		return this.datepipe.transform(date, mask);
	}

	stringToDate(dateStr: string) {
		// formato de entrada
		// dd/mm/yyyy hh:mm:ss
		if (dateStr === null) {
			return null;
		}
		const str = dateStr.split(' ');
		const dateAux = str[0].split('/');
		const horaAux = str[1].split(':');

		const year = Number(dateAux[2]);
		const month = Number(dateAux[1]) - 1;
		const date = Number(dateAux[0]);

		const hours = Number(horaAux[0]);
		const minutes = Number(horaAux[1]);
		const seconds = Number(horaAux[2]);

		return new Date(year, month, date, hours, minutes, seconds);
	}

	stringToDate2(dateStr: string, separador: string) {
		// formato de entrada
		// yyyy-mm-dd hh:mm:ss
		if (dateStr === null) {
			return null;
		}
		const str = dateStr.split(' ');
		const dateAux = str[0].split(separador);
		const horaAux = str[1].split(':');

		const year = Number(dateAux[0]);
		const month = Number(dateAux[1]);
		const date = Number(dateAux[2]);

		const hours = Number(horaAux[0]);
		const minutes = Number(horaAux[1]);
		const seconds = Number(horaAux[2]);

		return new Date(year, month, date, hours, minutes, seconds);
	}

	/*
		/**
	 * @param dateIn
	 * @return
	 *
	 * 	    Se a data passada for:
	 *  		Sabado => retorna Sexta feira
	 *  		Domingo => retonra Sexta feira
	 */
	getLastWorkDay(dateIn: Date): Date {

		const dateOut = new Date(dateIn);
		if (dateIn.getDay() === 6) {  // Sabado
			dateOut.setDate(dateOut.getDate() - 1);
		}
		if (dateIn.getDay() === 0) {  // Domingo
			dateOut.setDate(dateOut.getDate() - 2);
		}
		return dateOut;
		// if (dateIn.getDay() === 6) {
		// 	return new Date(dateIn.getTime() - (days01 * 24 * 60 * 60 * 1000));
		//   } else if (dateIn.getDay() ===0) {
		// 	return  new Date(dateIn.getTime() - (days02 * 24 * 60 * 60 * 1000));
		//   }
		//   return dateIn;
	}

	/*

		retorna a data menos o numero de dias passado por parametro

	*/
	getMinusDay(dateIn: Date, nroDay: number ) {

		const dateOut = new Date(dateIn);
		dateOut.setDate(dateOut.getDate() - nroDay );
		return dateOut;
	}

	/*

		retorna a data mais o numero de dias passado por parametro

	*/
	getPlusDay(dateIn: Date, nroDay: number ) {

		const dateOut = new Date(dateIn);
		dateOut.setDate(dateOut.getDate() + nroDay );
		return dateOut;
	}

	/*
	* retona o intervalo entre duas datas arredondando o valor para menos
	*/
	getIntervalBetweenDates( dateIni: Date, dateFim: Date ) {

		const diffTime = dateFim.getTime() - dateIni.getTime();
		const days = Math.ceil(diffTime / (1000 * 3600 * 24) );
		return days;
	}

}
