import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';

@Component({
	template: `<p>{{ "DEFAULT.LOGIN" | translate }}</p>`

})
export class LoginComponent implements OnInit {

	constructor() { }

	ngOnInit() {
		window.location.replace(environment._endpoint_auth);
	}

}
