import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	template: `	<p>{{ "DEFAULT.REDIRECT_HOME" | translate }}</p>`
})
export class OauthCallbackComponent implements OnInit {

	constructor(public router: Router) { }

	ngOnInit() {
		// this.router.navigateByUrl('/components/conta-corrente');
		this.router.navigateByUrl('/access');
	}

}
