import { Component, HostListener } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { MenuItem } from 'primeng/api';
import { MenuService } from '../services/menu/menu.service';

/** Angular component that utilizes the PrimeNG p-menubar component
 * to display the navigation menu for the application
 */
@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.css']
})
export class NavComponent {
	enableMenu: boolean;
	enableMenuAdministrativo: boolean;
	/** Array of PrimeNG MenuItems that are used to populate the p-menubar */
	navMenuItems: MenuItem[];
	/** whether or not the sidebar is open or not */
	sidebarVisible: boolean;
	/** toggles visibility of sidebar to true */
	showSidebar = () => { this.sidebarVisible = true; };
	/** toggles visibility of sidebar to false */
	hideSidebar = () => { this.sidebarVisible = false; };
	/** Listener to toggle sidebar to hidden when it is not displayed */
	@HostListener('window:resize', []) onResize() {
		if (this.sidebarVisible === true) {
			const sideBar = document.getElementById('app-sidebar');
			if (window.getComputedStyle(sideBar).display === 'none') {
				this.sidebarVisible = false;
			}
		}
	}

	/** Create a nav component and populate with necessary MenuItems (static data) */
	constructor(
		private menuService: MenuService,
		private translate: TranslateService
	) {
		// descomentar a linha abaixo quando for para producao
		// descomentar a linha abaixo quando for para producao
		// descomentar a linha abaixo quando for para producao
		// descomentar a linha abaixo quando for para producao
		// descomentar a linha abaixo quando for para producao
		// descomentar a linha abaixo quando for para producao
		//localStorage.clear();
		
		this.enableMenu = false;
		this.enableMenuAdministrativo = false;
		localStorage.setItem('menu', 'false');
		localStorage.setItem('menuAdm', 'false');
		this.subscribeMenu();

		this.translate.onLangChange.subscribe(
			(langChangeEvent: LangChangeEvent) => (this.loadMenu())
		);

	}

	onInit() {
		this.loadMenu();
	}

	subscribeMenu() {
		this.menuService.menuSubject.subscribe(value => {
			this.enableMenu = (localStorage.getItem('menu') === 'true');
			this.enableMenuAdministrativo = localStorage.getItem('menuAdm') === 'true';
			this.loadMenu();
		});
	}

	loadMenu() {

		this.navMenuItems = [
			{
				label: this.translate.instant('MENU.CONTA_CORRENTE'),
				routerLink: '/components/conta-corrente',
				command: this.hideSidebar,
				visible: this.enableMenu
			},
			{
				label: this.translate.instant('MENU.EXTRATOS'),
				routerLink: '/components/extrato',
				command: this.hideSidebar,
				visible: this.enableMenu
			},
			{
				label: this.translate.instant('MENU.PAGAMENTOS'),
				//routerLink: '/components/somente-consulta',
				routerLink: '/components/pagamento',
				command: this.hideSidebar,
				visible: this.enableMenu
				//visible: this.enableMenuAdministrativo

			},
			{
				label: this.translate.instant('MENU.TRANSFERENCIAS'),
				//routerLink: '/components/somente-consulta',
				routerLink: '/components/transferencia',
				command: this.hideSidebar,
				visible: this.enableMenu
				//visible: this.enableMenuAdministrativo
			},
			{
				label: this.translate.instant('MENU.RESGATES'),
				//routerLink: '/components/somente-consulta',
				routerLink: '/components/resgate',
				command: this.hideSidebar,
				visible: this.enableMenu
				//visible: this.enableMenuAdministrativo
			},
			{
				label: this.translate.instant('MENU.CONSULTA'),
				routerLink: '/components/consulta',
				command: this.hideSidebar,
				visible: this.enableMenu
			},
			{
				label: this.translate.instant('MENU.ADMINISTRACAO'),
				visible: this.enableMenuAdministrativo,
				items: [
					{
						label: this.translate.instant('MENU.SELECIONA_DEALER'),
						routerLink: '/components/adm/seleciona-dealer',
						command: this.hideSidebar,
						visible: this.enableMenuAdministrativo
					},
					{ separator: true },
					{
						label: this.translate.instant('MENU.DEALERS'),
						visible: this.enableMenuAdministrativo,
						items: [
							{
								label: this.translate.instant('MENU.DEALERS'),
								routerLink: '/components/adm/dealer',
								command: this.hideSidebar,
								visible: this.enableMenuAdministrativo
							},
							{
								label: this.translate.instant('MENU.CONTAS_DEALERS'),
								routerLink: '/components/adm/conta-dealer',
								command: this.hideSidebar,
								visible: this.enableMenuAdministrativo
							},
						//	{
						//		label: this.translate.instant('MENU.EMAIL_DEALERS'),
						//		routerLink: '/components/adm/email-dealer',
						//		command: this.hideSidebar,
						//		visible: this.enableMenuAdministrativo
						//	},
							{
								label: this.translate.instant('MENU.EXCECAO_DEALERS'),
								routerLink: '/components/adm/excecao-dealer',
								command: this.hideSidebar,
								visible: this.enableMenuAdministrativo
							},

							{
								label: this.translate.instant('MENU.GRUPO'),
								routerLink: '/components/adm/grupo',
								command: this.hideSidebar,
								visible: this.enableMenuAdministrativo
							},
							{
								label: this.translate.instant('MENU.GRUPO_DEALER'),
								routerLink: '/components/adm/grupo-dealer',
								command: this.hideSidebar,
								visible: this.enableMenuAdministrativo
							}
						]
					},
					{ separator: true },
					{
						label: this.translate.instant('MENU.SISTEMA'),
						visible: this.enableMenuAdministrativo,
						items: [
							{
								label: this.translate.instant('MENU.CONTROLE_ARQUIVO'),
								routerLink: '/components/adm/controle-arquivo',
								command: this.hideSidebar,
								visible: this.enableMenuAdministrativo
							},
							{
								label: this.translate.instant('MENU.ACESSO_INVALIDO'),
								routerLink: '/components/adm/acesso-invalido',
								command: this.hideSidebar,
								visible: this.enableMenuAdministrativo
							},
							{
								label: this.translate.instant('MENU.ACESSO'),
								routerLink: '/components/adm/acesso',
								command: this.hideSidebar,
								visible: this.enableMenuAdministrativo
							},
							{
								label: this.translate.instant('MENU.CONTROLE_DOCUMENTO'),
								routerLink: '/components/adm/controle-documento',
								command: this.hideSidebar,
								visible: this.enableMenuAdministrativo
							}
						]
					},
					{ separator: true },
					{
						label: this.translate.instant('MENU.CADASTRO_BANCO'),
						routerLink: '/components/adm/banco',
						command: this.hideSidebar,
						visible: this.enableMenuAdministrativo
					},
					{
						label: this.translate.instant('MENU.CONTA_FORD'),
						routerLink: '/components/adm/conta-ford',
						command: this.hideSidebar,
						visible: this.enableMenuAdministrativo
					},
					{
						label: this.translate.instant('MENU.PARAMETRO_SISTEMA'),
						routerLink: '/components/adm/parametro',
						command: this.hideSidebar,
						visible: this.enableMenuAdministrativo
					},
					{
						label: this.translate.instant('MENU.CODIGO_CONTABIL'),
						routerLink: '/components/adm/codigo-contabil',
						command: this.hideSidebar,
						visible: this.enableMenuAdministrativo
					},
					{
						label: this.translate.instant('MENU.CADASTRO_EMAIL'),
						routerLink: '/components/adm/email',
						command: this.hideSidebar,
						visible: this.enableMenuAdministrativo
					}
				]
			},
			{
				label: this.translate.instant('MENU.LOGIN'),
				routerLink: '/login',
				command: this.hideSidebar,
				visible: true
			},

		];
	}
}
