/**This file contains a constant object that has properties that are necessary
 * for the build found in the `production` configuration in `angular.json`.
 */
export const environment = {
	production: false,
	inmem: false,
	enableDebug: true,
	apiURL: 'https://api.qa01e.gcp.ford.com/dams-backend',
	_endpoint_auth: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize/wia?client_id=urn:damsfrontend:native.qa&resource=urn:damsfrontend:weblogin:qa&response_type=token&redirect_uri=https://wwwqa.dams.ford.com'

	// include production api base url string as a property below
};

/*
export const environment = {
	production: false,
	inmem: false,
	enableDebug: true,
	apiURL: 'https://dams-backend-qa.apps.pp01i.edc1.cf.ford.com',
	_endpoint_auth: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize/wia?client_id=urn:damsfrontend:native.qa&resource=urn:damsfrontend:weblogin:qa&response_type=token&redirect_uri=https://wwwqa.dams.ford.com'

	// include production api base url string as a property below
};

*/
