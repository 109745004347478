import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { tap, take } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

import { ContaFord, BancoApiDropBox } from './conta-ford.model';
import { CrudService } from 'src/app/services/crud/crud-service';
import { PageOrder } from '../../../interface/pageOrder/page-order';
import { Count } from 'src/app/interface/count';
import { ValidaPermissao } from 'src/app/interface/valida-permissao';
import { AppConstants } from 'src/app/utils/app-constants';

@Injectable({
	providedIn: 'root',
})
export class ContaFordService extends CrudService<ContaFord> {

	headers = new HttpHeaders( );

	constructor(protected http: HttpClient) {
		super(http, `${environment.apiURL}/api/v1/contaFord/`);
		
		this.headers = this.headers.append( 'Authorization', `Bearer ${localStorage.getItem('accessToken')}`);
	}

	findAllBancoDropBox(validaPermissao: ValidaPermissao) {
		const searchUrl = `${environment.apiURL}/api/v1/bancos/findAllToDropBox?pageNo=0&pageSize=50`;
		return this.http
			.post<any>(searchUrl , validaPermissao, {headers: this.headers} )
			.pipe(take(1));
	}

	getSearch(validaPermissao: ValidaPermissao, 
		strSearch: string,
		pageOrder: PageOrder
	) {
		const searchUrl = `${environment.apiURL}/api/v1/contaFord/getSearch?
		strSearch=${strSearch}&pageNo=${pageOrder.getPageNumber}&
		pageSize=${pageOrder.getPageSize}&fieldOrder=${pageOrder.getSortField}&
		sortOrder=${pageOrder.getSortOrder}`;
		return this.http
			.post<ContaFord>(searchUrl, validaPermissao, {headers: this.headers})
			.pipe(take(1));
	}

	delete(record: ContaFord, validaPermissao: ValidaPermissao): Observable<ContaFord> {
		validaPermissao.acaoBtn = AppConstants.OPER_CRUD_DELETE;
		const request = { payload: record, permission: validaPermissao, id: 0 };
		const header = new HttpHeaders({
			'Content-Type': 'application/json; charset=UTF-8'
		});
		const httpOptions = {
			headers: header,
			body: JSON.stringify({ request })
		};
		const searchUrl = `${environment.apiURL}/api/v1/contaFord/delete/${record.codBcoPk}/${record.nroAgenciaC}/${record.nroContaC}`;
		return this.http
			.delete<ContaFord>(searchUrl, httpOptions)
			.pipe(take(1));
	}

	getFindById(validaPermissao: ValidaPermissao, record: ContaFord): Observable<ContaFord> {
		const searchUrl = `${environment.apiURL}/api/v1/contaFord/
		findById?codBcoPk=${record.codBcoPk}&nroAgenciaC=${record.nroAgenciaC}
		&nroContaC=${record.nroContaC}`;
		return this.http.post<ContaFord>(searchUrl, validaPermissao, {headers: this.headers})
						.pipe(take(1));
	}

	findIsCtaAtiva(validaPermissao: ValidaPermissao,) {
		const searchUrl = `${environment.apiURL}/api/v1/contaFord/findIsCtaAtiva`;
		return this.http.post<Count>(searchUrl, validaPermissao, {headers: this.headers})
						.pipe(take(1));
	}
}
