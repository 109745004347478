import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { Menu } from './menu';

@Injectable({
	providedIn: 'root'
})
export class MenuService {
	[x: string]: any;

	menu: Menu = new Menu(false, false);

	/** The User ID to be stored from authentication and distributed to other components in the app */
	menuSubject: BehaviorSubject<Menu> = new BehaviorSubject<Menu>(this.menu);
	/** creates a new instance of the MenuSubjectService
	 */
	constructor() {
		const menu: Menu = new Menu(false, false);
		this.setMenuSubject(menu);
	}

	/** Stores user id
	 *
	 * @param menuSubject the user ID to be stored on in the menuSubject property of this service
	 */
	setMenuSubject(menuSubject: Menu) {
		this.menuSubject.next(menuSubject);
	}

	/** gets the user ID stored in this service
	 *
	 * @returns the menuSubject cast as an observable to be subscribed to by other components
	 */
	getMenuSubject(): Observable<Menu> {
		return this.menuSubject;
	}

}
