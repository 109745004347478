import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { take } from 'rxjs/operators';
// import { Observable } from 'rxjs';

import { CodigoContabil } from './codigo-contabil.model';
import { CrudService } from '../../../services/crud/crud-service';
import { PageOrder } from '../../../interface/pageOrder/page-order';
import { ValidaPermissao } from 'src/app/interface/valida-permissao';

@Injectable({
	providedIn: 'root',
})
export class CodigoContabilService extends CrudService<CodigoContabil> {

	headers = new HttpHeaders( ); 
	
	constructor(protected http: HttpClient) {
		super(http, `${environment.apiURL}/api/v1/codigoContabil/`);

		this.headers = this.headers.append( 'Authorization', `Bearer ${localStorage.getItem('accessToken')}`);
		
	}

	getSearch(validaPermissao: ValidaPermissao, 
		strSearch: string, pageOrder: PageOrder) {
		const searchUrl = `${environment.apiURL}/api/v1/codigoContabil/getSearch?
		strSearch=${strSearch}&pageNo=${pageOrder.getPageNumber}&pageSize=${pageOrder.getPageSize}&
		fieldOrder=${pageOrder.getSortField}&sortOrder=${pageOrder.getSortOrder}`;
		return this.http
			.post<CodigoContabil>(searchUrl, validaPermissao,  { headers: this.headers})
			.pipe(take(1));
	}

}
