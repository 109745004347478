import { Message } from 'primeng/api';
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from 'src/app/oauth/login/login.service';
import { DealerCheck } from './dealer-check.model';
import { DateService } from '../date/date.service';
import { ExportService } from '../export/export.service';
import { BaseService } from './base.service';
import { PageOrder } from 'src/app/interface/pageOrder/page-order';
import { LocatorService } from '../locator/locator.service';
import { Observable, of } from 'rxjs';
import { ProgressSpinnerCtrlService } from 'src/app/utils/progress-Spinner-ctrl.service';
import { AppConstants } from 'src/app/utils/app-constants';
import { ValidaPermissao } from 'src/app/interface/valida-permissao';

export class Base {

	isDisplayDealerNaoSelecionado: boolean;
	isDealer: boolean;

	username: string;
	codUserPk: string;
	dealerCod: string;
	dealerName: string;
	dealerCodeName: string;
	validaPermissao: ValidaPermissao;
	codDealer = 0;
	ccVlrSaldo = 0;
	displayDialogCheck = false;

	msgsScreen: Message[] = [];
	msgsDlgNewReg: Message[] = [];

	accessValid = false;
	dealerCheck: DealerCheck;

	pageOrder: PageOrder;
	theTotalElements: number;
	rowsPage = 15;
	rowsPageWithFilter = this.rowsPage - 2;
	firstPage = 0;

	ipAddress: string;

	baseService: BaseService;
	loginService: LoginService;
	dateTimeService: DateService;
	exportService: ExportService;
	router: Router;
	spinnerService: ProgressSpinnerCtrlService;
	isProgressSpinner: boolean;

	constructor(
		protected service: any
	) {
		this.loginService = LocatorService.injector.get(LoginService);
		this.dateTimeService = LocatorService.injector.get(DateService);
		this.exportService = LocatorService.injector.get(ExportService);
		this.router = LocatorService.injector.get(Router);
		this.loginService = LocatorService.injector.get(LoginService);

		this.spinnerService = LocatorService.injector.get(ProgressSpinnerCtrlService);

		this.loginService.username$.subscribe(username => this.username = username);
		this.loginService.dealerCod$.subscribe(dealerCod => this.dealerCod = dealerCod);
		this.loginService.dealerName$.subscribe(dealerName => this.dealerName = dealerName);
		this.loginService.codUserPk$.subscribe(codUserPk => this.codUserPk = codUserPk);

		this.spinnerService.isProgressSpinner$.subscribe(isProgressSpinner => this.isProgressSpinner = isProgressSpinner);
	}

	onInit() {

		this.spinnerService.progressSpinnerOn();

		this.isDisplayDealerNaoSelecionado = false;

		this.dealerCod = localStorage.getItem('codDn');
		this.dealerName = localStorage.getItem('nomeC');
		//this.username = localStorage.getItem('username');
		this.username = localStorage.getItem('codUserPk');
		this.ipAddress = localStorage.getItem('ipAddress');

		this.codUserPk = localStorage.getItem('codUserPk');

		this.loginService.dealerCod$.subscribe(dealerCod => this.dealerCod = dealerCod);
		this.loginService.dealerName$.subscribe(dealerName => this.dealerName = dealerName);

		this.dealerCodeName = this.dealerCod + ' - ' + this.dealerName;
		this.codDealer = parseInt(localStorage.getItem('codDn'), 10);

		if (isNaN(this.codDealer)) {
			this.codDealer = 0;
		}

		this.dealerCheck = this.newDealerCheck();

		this.isDisplayDealerNaoSelecionado = (parseInt(this.dealerCod, 10) === 0);

		this.isDealer = (localStorage.getItem('grupoC') === 'D');

		this.loadSteps();

		this.isAccessValid();

		this.spinnerService.progressSpinnerOff();

	}

	loadSteps() {

	}

	async isAccessValid() {
		this.spinnerService.progressSpinnerOn();
		this.msgsDlgNewReg = [];
		this.accessValid = false;

		this.validaPermissao = {
			cdsidInclC: this.codUserPk,
			codDealer: parseInt(this.dealerCod, 10),
			codUsrC: this.codUserPk,
			ipAddressC: this.ipAddress,
			acaoBtn: AppConstants.OPER_ACESSO_A_API,
			token: localStorage.getItem('accessToken')
		};

		await this.service.isAccessValid(this.validaPermissao)
			.then( data => this.accessValid = data,
				   this.spinnerService.progressSpinnerOff()
				 );

		if (this.accessValid) {
			this.spinnerService.progressSpinnerOff();
			if (parseInt(this.dealerCod, 10) !== 0) {
				this.getDealerCheck();
			}
		} else {
			this.spinnerService.progressSpinnerOff();
			this.router.navigate(['/no-access']);
		}
	}

	newDealerCheck() {
		return {
			codDealer: null,
			dtMovto: null,
			vlrSaldoMinimoExcecao: 0,
			isDialogVisible: false,
			isCasaSaldoNegativo: false,
			isDealerSaldoNegativo: false,
			isTransferenciaAtiva: false,
			isResgateAtivo: false,
			isPagtoFavAtivo: false,
			isBusinessDay: false,
			isVlrMinimoSolicResgate: false,
			vlrMinimoSolicResgate: 0,
			isExisteLancamentoSaldoCc: false,
			isExisteLancamentoSaldoCp: false,
			isExisteSaldoNoDiaCc: false,
			isExisteSaldoNoDiaCp: false,
			isExecResgC: false,
			isExecPgtoFavC: false,
			isExecTransfC: false

		};
	}

	private async getDealerCheck() {

		this.validaPermissao = {
			cdsidInclC: this.codUserPk,
			codDealer: parseInt(this.dealerCod, 10),
			codUsrC: this.codUserPk,
			ipAddressC: this.ipAddress,
			acaoBtn: AppConstants.OPER_ACESSO_A_API,
			token: localStorage.getItem('accessToken')
		};

		this.spinnerService.progressSpinnerOn();
		await this.service
				.getDealerCheck(this.validaPermissao, this.codDealer, this.dateTimeService.dateTimeStartNow())
				.then(data => this.dealerCheck = data);
		
		this.displayDialogCheck = this.dealerCheck.isDialogVisible;
		this.spinnerService.progressSpinnerOff();

		this.onLoad(this.displayDialogCheck);
	}

	onLoad(displayDialogCheck: boolean) {

	}

}
