import { Component, OnInit } from '@angular/core';
import {
	ConfirmationService,
	SelectItem,
} from 'primeng/api';
import { empty } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ContaDealer } from './conta-dealer.model';
import { ContaDealerService } from './conta-dealer.service';
import { PageOrder } from '../../../interface/pageOrder/page-order';
import { DealerService } from '../../adm/dealer/dealer.service';
import { ComponentBase } from 'src/app/services/componentBase/component-base';
import { ErrorHandler } from 'src/app/app-error-handler';
import { LoginService } from 'src/app/oauth/login/login.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/utils/app-constants';

interface BancoDropDown {
	codBcoPk: number;
	nroBco: string;
	nmBcoC: string;
}

@Component({
	selector: 'conta-dealer',
	templateUrl: './conta-dealer.component.html',
	styleUrls: ['./conta-dealer.component.scss'],
})
export class ContaDealerComponent extends ComponentBase<ContaDealer>
	implements OnInit {

	statusList: SelectItem[];
	// //<!-- #################### PESQUISA DEALER ########################a -->

	colsDealer: any[];
	sortFieldDealer = 'dealerNameC';
	pageOrderDealer: PageOrder;
	displaySearhDealer = false;
	titleSearchDealer: 'GRP_DEALER.TITLE';

	// //<!-- #################### PESQUISA DEALER ########################a -->

	bancosDropDown: any[];
	bancoDropDownSelected: BancoDropDown;
	serviceDealerSearch: DealerService;

	constructor(
		protected service: ContaDealerService,
		protected serviceDealer: DealerService,
		protected confirmationService: ConfirmationService,
		protected loginService: LoginService,
		protected router: Router,
	) {
		super(service, confirmationService, loginService, router);

		this.serviceDealerSearch = serviceDealer;
	}

	ngOnInit() {

		this.onInit();

		this.sortedField = 'favorecidoC';
		this.pageOrder = new PageOrder(0, this.pageSize, this.sortedField, AppConstants.ORDER_INDEX_ASCENDING);
		this.strSearch = '';
		this.theTotalElements = 0;
		this.displayDialog = false;

		this.colsTab = [
			{ field: 'codDealerPk'	    , index: 'contaDealerBOPK.codDealerPk' , header: 'DEALER.DEALER', mask: '', alignText: 'center' },
			{ field: 'dealerNameC'	    , index: ''       		  , header: 'DEALER.NOME', excludeSort: 'true',  mask: '', alignText: 'left' },
			{ field: 'nroBco'			, index: 'bancoBO.nroBco' , header: 'BANCO.NRO_BANCO', mask: '', alignText: 'center' },
			{ field: 'nmBcoC'			, index: 'bancoBO.nmBcoC' , header: 'BANCO.NM_BANCO', mask: '', alignText: 'center' },
			{ field: 'nroAgenciaC'		, index: 'contaDealerBOPK.nroAgenciaC'	, header: 'BANCO_COD_AGENCIA', mask: '', alignText: 'center' },
			{ field: 'nroContaC'		, index: 'contaDealerBOPK.nroContaC'	, header: 'BANCO.NRO_CONTA', mask: '', alignText: 'center' },
			{ field: 'favorecidoC'		, index: ''       		, header: 'CTA_DEALER.FAVORECIDO', mask: '', alignText: 'left' },
			{ field: 'statusDescC'		, index: 'statusC'		, header: 'DEFAULT.ATIVO', mask: '', alignText: 'center' }
		];

		this.clearRegData();

		this.clearNewRegData();

		this.statusList = [
			{ label: 'Selecione', value: '' },
			{ label: 'Ativo', value: 'S' },
			{ label: 'Inativo', value: 'N' },
		];

		//<!-- #################### PESQUISA DEALER ########################a -->

		this.pageOrderDealer = new PageOrder(0, 14, 'dealerNameC', AppConstants.ORDER_INDEX_ASCENDING);

		this.colsDealer = [
			{ field: 'codDealerPk', index: ''       , header: 'DEFAULT.CODIGO', mask: '' , alignText: 'center' },
			{ field: 'dealerNameC', index: ''       , header: 'DEALER.NOME'   , mask: '' , alignText: 'left' },
			{ field: 'statusDescC', index: 'statusC', header: 'DEFAULT.STATUS', mask: '' , alignText: 'center' },
		];

		//<!-- #################### PESQUISA DEALER ########################a -->

		this.loadBancos();

	}

	clearNewRegData() {
		this.newRegData = {
			codDealerPk: null,
			dealerNameC: null,
			codNomeDealerC: null,
			codBcoPk: null,
			nroBco: null,
			nmBcoC: null,
			nroAgenciaC: null,
			nroContaC: null,
			favorecidoC: null,
			statusC: null,
			statusDescC: null,
			codIdentC: null,
			nrCnpjDepC: null,
			cdsidIncl: null,
			dtIncl: null,
			cdsidUltAlt: null,
			dtUltAlt: null,
		};

		this.bancoDropDownSelected = {
			codBcoPk: null,
			nroBco: null,
			nmBcoC: null,
		};

	}

	//<!-- #################### PESQUISA DEALER ########################a -->
	onSearchDealerDisplay() {
		this.displaySearhDealer = true;
	}

	onSearchDealerRowSelect(event) {
		this.newRegData.codDealerPk = event.value.codDealerPk;
		this.newRegData.dealerNameC = event.value.dealerNameC;
		this.displaySearhDealer = false;
	}

	processloadRowSelect() {
		return (data) => {
			this.newRegData = data;
			this.bancoDropDownSelected = this.regToDropDown(this.newRegData);
			this.displayDialog = true;
			this.spinnerService.progressSpinnerOff();
		};
	}

	onSearchHide(event) {
		this.displaySearhDealer = false;
	}
	//<!-- #################### PESQUISA DEALER ########################a -->

	regToDropDown(newRegData: ContaDealer): any {
		let bancoTmp: BancoDropDown;
		bancoTmp = {
			codBcoPk: null,
			nroBco: null,
			nmBcoC: null,
		};

		bancoTmp.codBcoPk = newRegData.codBcoPk;
		bancoTmp.nroBco = newRegData.nroBco;
		bancoTmp.nmBcoC = newRegData.nmBcoC;
		return bancoTmp;
	}

	onChangeDrop(evento) {
		this.newRegData.codBcoPk = evento.value.codBcoPk;
		this.newRegData.nroBco = evento.value.nroBco;
		this.newRegData.nmBcoC = evento.value.nmBcoC;
		this.newRegData.nroAgenciaC = '';
		this.newRegData.nroContaC = '';
	}

	loadBancos() {
		this.validaPermissao = {
			cdsidInclC: localStorage.getItem('codUserPk'),
			codDealer: parseInt(localStorage.getItem('codDn'), 10),
			codUsrC: localStorage.getItem('codUserPk'),
			ipAddressC: localStorage.getItem('ipAddress'),
			acaoBtn: AppConstants.OPER_ACESSO_A_API,
			token: localStorage.getItem('accessToken')
		};
		this.spinnerService.progressSpinnerOn();
		this.service
			.findAllBancoDropBox( this.validaPermissao )
			.pipe(
				catchError(error => {
					this.spinnerService.progressSpinnerOff();
					this.msgsDlgNewReg = [];
					this.msgsDlgNewReg.push(ErrorHandler.messageDlgErroNew(error, 'loadBancos'));
					ErrorHandler.handleError(error);
					return empty();
				})
			)
			.subscribe((data) => {
				this.bancosDropDown = data.content;
				this.spinnerService.progressSpinnerOff();
			});
	}
}
