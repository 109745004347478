import { ValidaParametro } from './../../../services/transferenciaBase/transferencia-valida-parametro.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { tap, take } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

import { ExcecaoDealer } from './excecao-dealer.model';
import { CrudService } from 'src/app/services/crud/crud-service';
import { PageOrder } from '../../../interface/pageOrder/page-order';
import { AppConstants } from 'src/app/utils/app-constants';
import { ValidaPermissao } from 'src/app/interface/valida-permissao';

@Injectable({
	providedIn: 'root',
})
export class ExcecaoDealerService extends CrudService<ExcecaoDealer> {
	
	headers = new HttpHeaders();

	constructor(protected http: HttpClient) {
		super(http, `${environment.apiURL}/api/v1/excecaoDealers/`);

		this.headers = this.headers.append( 'Authorization', `Bearer ${localStorage.getItem('accessToken')}`);

	}

	getSearch(  validaPermissao: ValidaPermissao, strSearch: string, pageOrder: PageOrder
	) {
		const searchUrl = `${environment.apiURL}
		/api/v1/excecaoDealers/findByExcecaoDealerNameCContainingIgnoreCase?strSearch=
		${strSearch}&pageNo=${pageOrder.getPageNumber}&pageSize=${pageOrder.getPageSize}
		&fieldOrder=${pageOrder.getSortField}&sortOrder=${pageOrder.getSortOrder}`;
		return this.http.post<ExcecaoDealer>(searchUrl, validaPermissao, { headers: this.headers})
						.pipe(take(1));
	}

	delete(record: ExcecaoDealer, validaPermissao: ValidaPermissao): Observable<ExcecaoDealer> {
		validaPermissao.acaoBtn = AppConstants.OPER_CRUD_DELETE;
		const request = { payload: record, permission: validaPermissao, id: record['codDealerPk'] };
		const header = new HttpHeaders({
			'Content-Type': 'application/json; charset=UTF-8',
			'Authorization' : `Bearer ${localStorage.getItem('accessToken')}`
		});
		const httpOptions = {
			headers: header,
			body: JSON.stringify({ request })
		};
		const searchUrl = `${environment.apiURL}/api/v1/excecaoDealers/delete/${record.codDealerPk}`;
		return this.http.delete<ExcecaoDealer>(searchUrl, httpOptions)
						.pipe(take(1));
	}

	getFindById(validaPermissao: ValidaPermissao, record: ExcecaoDealer) {
		const searchUrl = `${environment.apiURL}/api/v1/excecaoDealers/findById?
		codDealer=${record.codDealerPk}`;
		return this.http.post<ExcecaoDealer>(searchUrl, validaPermissao, { headers: this.headers})
						.pipe(take(1));
	}
}
