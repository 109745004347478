import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { tap, take } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

import { EmailDealer } from './email-dealer.model';
import { CrudService } from 'src/app/services/crud/crud-service';
import { PageOrder } from '../../../interface/pageOrder/page-order';
import { ValidaPermissao } from 'src/app/interface/valida-permissao';
import { AppConstants } from 'src/app/utils/app-constants';

@Injectable({
	providedIn: 'root',
})
export class EmailDealerService extends CrudService<EmailDealer> {
	constructor(protected http: HttpClient) {
		super(http, `${environment.apiURL}/api/v1/emailDealers/`);
	}

	getSearch( validaPermissao: ValidaPermissao, strSearch: string, pageOrder: PageOrder
	) {
		const searchUrl = `${environment.apiURL}/api/v1/emailDealers/findByEmailDealerNameCContainingIgnoreCase?strSearch=
			${strSearch}&pageNo=${pageOrder.getPageNumber}&pageSize=${pageOrder.getPageSize}
			&fieldOrder=${pageOrder.getSortField}&sortOrder=${pageOrder.getSortOrder}`;
		return this.http
			.post<EmailDealer>(searchUrl,  validaPermissao, { headers: this.headers})
			.pipe(take(1));
	}

	delete(record: EmailDealer, validaPermissao: ValidaPermissao): Observable<EmailDealer> {
		validaPermissao.acaoBtn = AppConstants.OPER_CRUD_DELETE;
		const request = { payload: record, permission: validaPermissao, id: 0 };
		const header = new HttpHeaders({
			'Content-Type': 'application/json; charset=UTF-8'
		});
		const httpOptions = {
			headers: header,
			body: JSON.stringify({ request })
		};
		const searchUrl = `${environment.apiURL}/api/v1/emailDealers/delete/${record.codDealerPk}`;
		return this.http
			.delete<EmailDealer>(searchUrl, httpOptions)
			.pipe(take(1));
	}

	getFindById(validaPermissao: ValidaPermissao, record: EmailDealer) {
		const searchUrl = `${environment.apiURL}/api/v1/emailDealers/findById?codDealer=${record.codDealerPk}`;
		return this.http
			.post<EmailDealer>(searchUrl, validaPermissao, { headers: this.headers})
			.pipe(take(1));
	}
}
