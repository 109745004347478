import { OnInit } from '@angular/core';

export class PageOrder {
	private thePageNumber: number;
	private thePageSize: number;
	private theSortField: string;
	private theSortOrder: number;

	constructor(
		private pageNumber: number,
		private pageSize: number,
		private sortField: string,
		private sortOrder: number
	) {
		this.thePageNumber = pageNumber;
		this.thePageSize = pageSize;
		this.theSortField = sortField;
		this.theSortOrder = sortOrder;
	}

	// ngOnInit() { }

	public get getPageNumber(): number {
		return this.thePageNumber;
	}

	public set setPageNumber(pageNumber: number) {
		this.thePageNumber = pageNumber;
	}

	public get getPageSize(): number {
		return this.thePageSize;
	}

	public set setPageSize(pageSize: number) {
		this.thePageSize = pageSize;
	}

	public get getSortField(): string {
		return this.theSortField;
	}

	public set setSortField(sortField: string) {
		this.theSortField = sortField;
	}

	public get getSortOrder(): number {
		return this.theSortOrder;
	}

	public set setSortOrder(sortOrder: number) {
		this.theSortOrder = sortOrder;
	}
}
