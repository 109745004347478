import { Component, VERSION, LOCALE_ID } from '@angular/core';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { LocaleService } from './services/locale/locale.service';
/** Class for the app component that is bootstrapped to run the application
 */

import { LoginService } from './oauth/login/login.service';
import { ProgressSpinnerCtrlService } from './utils/progress-Spinner-ctrl.service';

@Component({
	selector: 'body',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {

	languageActive = false;
	language: SelectItem[];
	localeDef: any;

	/** the Angular version */
	version = VERSION.full;
	/** whether we are production or not */
	envProd = environment.production;
	currentYear = new Date();

	username: string;
	dealerCod: string;
	dealerName: string;

	isProgressSpinner: boolean;

	constructor(
		private session: LocaleService,
		public translate: TranslateService,
		private loginService: LoginService,
		protected spinnerService: ProgressSpinnerCtrlService
	) {
		this.translate.addLangs(['pt', 'en', 'es']);
		this.translate.setDefaultLang('pt');
		this.translate.use('pt');

		this.isProgressSpinner = false;

		this.language = [
			{ label: 'Português', value: 'pt' },
			{ label: 'English', value: 'en' },
			{ label: 'Espanol', value: 'es' }

		];

		this.username = localStorage.getItem('codUserPk');
		//this.username = localStorage.getItem('username');
		this.dealerCod = localStorage.getItem('codDn');
		this.dealerName = localStorage.getItem('nomeC');

		this.getUsername();

		this.getDealer();

		this.spinnerService.isProgressSpinner$.subscribe( isProgressSpinner => this.isProgressSpinner = isProgressSpinner );
	}

	 getUsername() {
	 	this.loginService.username$.subscribe(username => this.username = username);
	 }

	getDealer() {
		this.loginService.dealerCod$.subscribe(dealerCod => this.dealerCod = dealerCod);
		this.loginService.dealerName$.subscribe(dealerName => this.dealerName = dealerName);
	}

	changeLang(lang: string) {
		this.translate.use(lang);
		this.languageActive = true;

		setTimeout(() => {
			this.session.registerCulture(lang);
		}, 1000);
		this.localeDef = this.session.getLocale();
	}

}
