import { Component, OnInit } from '@angular/core';
import {
	ConfirmationService,
	SelectItem,
} from 'primeng/api';
import { MessageService } from 'primeng/api';

import { CodigoContabil } from './codigo-contabil.model';
import { CodigoContabilService } from './codigo-contabil.service';
import { PageOrder } from '../../../interface/pageOrder/page-order';
import { ComponentBase } from 'src/app/services/componentBase/component-base';
import { LoginService } from 'src/app/oauth/login/login.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/utils/app-constants';

@Component({
	selector: 'app-codigo-contabil',
	templateUrl: './codigo-contabil.component.html',
	styleUrls: ['./codigo-contabil.component.scss'],
	providers: [MessageService],
})

export class CodigoContabilComponent extends ComponentBase<CodigoContabil> implements OnInit {

	statusList: SelectItem[];

	constructor(
		protected service: CodigoContabilService,
		protected confirmationService: ConfirmationService,
		protected loginService: LoginService,
		protected router: Router,
	) {
		super(service, confirmationService, loginService, router);
	}

	ngOnInit() {

		this.onInit();

		this.sortedField = 'dscContabilC';
		this.pageOrder = new PageOrder(0, this.pageSize, this.sortedField, AppConstants.ORDER_INDEX_ASCENDING);
		this.strSearch = '';
		this.theTotalElements = 0;
		this.displayDialog = false;

		this.clearRegData();

		this.clearNewRegData();

		this.colsTab = [
			{ field: 'codContabil'	, index: ''       , header: 'COD_CONTABIL.COD_CONTABIL', mask: ''  , alignText: 'center'},
			{ field: 'dscContabilC'	, index: ''       , header: 'COD_CONTABIL.DSC_CONTABIL', mask: ''  , alignText: 'left'},
		];

	}

	preClearNewRegData() {
		this.newRegData = {
			id: null,
			codContabil: null,
			dscContabilC: null,
			cdsidInclC: null,
			dtInclS: null,
			cdsidUltAltC: null,
			dtUltAltS: null,
		};
	}
}
