import {
	ConfirmationService,
	Message,
	LazyLoadEvent,
} from 'primeng/api';
import { empty } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgForm } from '@angular/forms';

import { PageOrder } from '../../interface/pageOrder/page-order';
import { ErrorHandler } from 'src/app/app-error-handler';
import { LoginService } from 'src/app/oauth/login/login.service';
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ProgressSpinnerCtrlService } from 'src/app/utils/progress-Spinner-ctrl.service';
import { LocatorService } from '../locator/locator.service';
import { AppConstants } from 'src/app/utils/app-constants';
import { ValidaPermissao } from 'src/app/interface/valida-permissao';

export class ComponentBase<T> {

	constructor(
		protected service,
		protected confirmationService: ConfirmationService,
		protected loginService: LoginService,
		protected router: Router,

	) {
		this.loginService.username$.subscribe(username => this.username = username);
		this.loginService.dealerCod$.subscribe(dealerCod => this.dealerCod = dealerCod);
		this.loginService.dealerName$.subscribe(dealerName => this.dealerName = dealerName);
		this.loginService.codUserPk$.subscribe(codUserPk => this.codUserPk = codUserPk);

		this.spinnerService = LocatorService.injector.get(ProgressSpinnerCtrlService);

		this.spinnerService.isProgressSpinner$.subscribe(isProgressSpinner => this.isProgressSpinner = isProgressSpinner);

	}

	spinnerService: ProgressSpinnerCtrlService;
	isProgressSpinner: boolean;

	username: string;
	dealerCod: string;
	dealerName: string;
	validaPermissao: ValidaPermissao;

	ipAddress: string;
	codUserPk: string;

	pageSize = 20;
	newRegFlg = true;
	newRegData: T;
	displayDialog: boolean;
	colsTab: any[];
	sortedField: string;

	sortOrder: number;
	pageOrder: PageOrder;

	datasource: T[];
	strSearch = '';

	isLoading$: EventEmitter<boolean> = new EventEmitter();

	theTotalElements = 0;
	firstPage = 0;

	msgsScreen: Message[] = [];
	msgsDlgNewReg: Message[] = [];

	accessValid = false;

	onInit() {

		this.dealerCod = localStorage.getItem('codDn');
		this.dealerName = localStorage.getItem('nomeC');
		// this.username = localStorage.getItem('username');
		this.username = localStorage.getItem('codUserPk');
		this.ipAddress = localStorage.getItem('ipAddress');
		this.codUserPk = localStorage.getItem('codUserPk');

		this.isAccessValid();
	}

	onRowSelect(event) {
		this.newRegFlg = false;
		this.loadRowSelect(event.data);
	}

	loadRowSelect(reg: T) {
		this.validaPermissao = {
			cdsidInclC: localStorage.getItem('codUserPk'),
			codDealer: parseInt(localStorage.getItem('codDn'), 10),
			codUsrC: localStorage.getItem('codUserPk'),
			ipAddressC: localStorage.getItem('ipAddress'),
			acaoBtn: AppConstants.OPER_ACESSO_A_API,
			token: localStorage.getItem('accessToken')
		};
		this.spinnerService.progressSpinnerOn();
		this.clearNewRegData();
		this.service
			.getFindById(this.validaPermissao, reg)
			.pipe(
				catchError(error => {
					this.spinnerService.progressSpinnerOff();
					this.msgsDlgNewReg = [];
					this.msgsDlgNewReg.push(ErrorHandler.messageDlgErroNew(error, 'loadRowSelect'));
					ErrorHandler.handleError(error);
					return empty();
				})
			)
			.subscribe(this.processloadRowSelect());
	}

	processloadRowSelect() {
		return (data) => {
			this.newRegData = data;
			this.displayDialog = true;
			this.firstPage = 0;
			this.spinnerService.progressSpinnerOff();
		};
	}

	clone(obj: any) {
		return Object.assign({}, obj);
	}

	loadLazy(event: LazyLoadEvent) {
		if (!this.isProgressSpinner) {
			this.pageOrder.setPageNumber = event.first / event.rows;
			this.pageOrder.setPageSize = event.rows;
			for (const iterator of this.colsTab ) {
				if ( iterator.field === event.sortField ) {
					if ( iterator.field === event.sortField  &&  iterator.index !== '' ) {
						this.pageOrder.setSortField = iterator.index;
					} else {
						this.pageOrder.setSortField = event.sortField;
					}
				}
			}
			this.pageOrder.setSortOrder = event.sortOrder;
			if (!(this.strSearch === null || this.strSearch.length === 0)) {
				this.loadSearch();
			} else {
				this.load();
			}
		}
	}

	load() {
		this.validaPermissao = {
			cdsidInclC: localStorage.getItem('codUserPk'),
			codDealer: parseInt(localStorage.getItem('codDn'), 10),
			codUsrC: localStorage.getItem('codUserPk'),
			ipAddressC: localStorage.getItem('ipAddress'),
			acaoBtn: AppConstants.OPER_ACESSO_A_API,
			token: localStorage.getItem('accessToken')
		};
		this.spinnerService.progressSpinnerOn();
		this.datasource = null;
		this.service
			.getAllPageOrder(this.validaPermissao, this.pageOrder)
			.pipe(
				catchError(error => {
					this.spinnerService.progressSpinnerOff();
					ErrorHandler.handleError(error);
					this.msgsDlgNewReg = [];
					this.msgsDlgNewReg.push(ErrorHandler.messageDlgErroNew(error, 'Load'));
					return empty();
				})
			)
			.subscribe(this.processResult());
	}

	onSearch() {
		this.msgsDlgNewReg = [];
		if (!(this.strSearch === null || this.strSearch.length === 0)) {
			this.pageOrder.setPageNumber = 0;
		}
		this.loadSearch();
	}

	loadSearch() {
		this.validaPermissao = {
			cdsidInclC: localStorage.getItem('codUserPk'),
			codDealer: parseInt(localStorage.getItem('codDn'), 10),
			codUsrC: localStorage.getItem('codUserPk'),
			ipAddressC: localStorage.getItem('ipAddress'),
			acaoBtn: AppConstants.OPER_ACESSO_A_API,
			token: localStorage.getItem('accessToken')
		};
		this.spinnerService.progressSpinnerOn();
		this.datasource = null;
		if (!(this.strSearch === null || this.strSearch.length === 0)) {
			this.service
				.getSearch(this.validaPermissao, this.strSearch, this.pageOrder
				)
				.pipe(
					catchError(error => {
						this.spinnerService.progressSpinnerOff();
						ErrorHandler.handleError(error);
						this.msgsDlgNewReg = [];
						this.msgsDlgNewReg.push(ErrorHandler.messageDlgErroNew(error, 'loadSearch'));
						return empty();
					})
				)
				.subscribe(this.processResult());
		}
	}

	processResult() {
		return (data) => {
			this.datasource = data.content;
			this.theTotalElements = data.totalElements;
			this.spinnerService.progressSpinnerOff();
		};
	}

	onClean() {
		this.msgsDlgNewReg = [];
		this.strSearch = '';
		this.onRecharge();
	}

	onRecharge() {

		this.load();
	}

	onShowDialogToAdd() {
		this.msgsScreen = [];
		this.msgsDlgNewReg = [];
		this.newRegFlg = true;
		this.clearNewRegData();
		this.displayDialog = true;
	}

	clearNewRegData() {
		this.preClearNewRegData();

	}

	preClearNewRegData() { }

	clearRegData() {

		this.preClearRegData();
		this.msgsDlgNewReg = [];
		this.datasource = null;

	}

	preClearRegData() { }

	onPreSave(): boolean { return true; }

	onSave(form: NgForm) {
		this.validaPermissao = {
			cdsidInclC: localStorage.getItem('codUserPk'),
			codDealer: parseInt(localStorage.getItem('codDn'), 10),
			codUsrC: localStorage.getItem('codUserPk'),
			ipAddressC: localStorage.getItem('ipAddress'),
			acaoBtn: AppConstants.OPER_ACESSO_A_API,
			token: localStorage.getItem('accessToken')
		};
		this.spinnerService.progressSpinnerOn();
		if (this.onPreSave()) {

			let detailSuccess = 'Registro criado com sucesso!';
			let detailError = 'Erro ao tentar criar o Registro';
			let msgLog = 'Criação terminada com sucesso';
			if (!this.newRegFlg && !this.newRegFlg) {
				detailSuccess = 'Registro alterado com sucesso!';
				detailError = 'Erro ao tentar alterar o Registro';
				msgLog = 'Alteração terminada com sucesso';
			}
			this.service.save(this.newRegData, this.newRegFlg, this.validaPermissao).subscribe(
				(success) => {
					this.msgsScreen = [];
					this.msgsScreen.push({
						severity: 'success',
						summary: '',
						detail: detailSuccess,
					});
					setTimeout(() => {
						this.msgsScreen = [];
						form.reset();
						this.displayDialog = false;
						this.load();
					}, 2000);
					this.spinnerService.progressSpinnerOff();
				},
				(error) => {
					this.spinnerService.progressSpinnerOff();
					ErrorHandler.handleError(error);
					this.msgsScreen = [];
					this.msgsScreen.push(ErrorHandler.messageDlgErroNew(error, 'Save'));
				}
			);
		}
	}

	onDelete() {
		this.validaPermissao = {
			cdsidInclC: localStorage.getItem('codUserPk'),
			codDealer: parseInt(localStorage.getItem('codDn'), 10),
			codUsrC: localStorage.getItem('codUserPk'),
			ipAddressC: localStorage.getItem('ipAddress'),
			acaoBtn: AppConstants.OPER_ACESSO_A_API,
			token: localStorage.getItem('accessToken')
		};
		this.confirmationService.confirm({
			accept: () => {
				this.spinnerService.progressSpinnerOn();
				this.service.delete(this.newRegData, this.validaPermissao).subscribe(
					(success) => {
						this.msgsScreen = [];
						this.msgsScreen.push({
							severity: 'success',
							summary: '',
							detail: 'Registro Excluido.',
						});
						setTimeout(() => {
							this.msgsScreen = [];
							this.displayDialog = false;
							this.load();
						}, 1000);
						this.spinnerService.progressSpinnerOff();
					},
					(error) => {
						ErrorHandler.handleError(error);
						this.msgsScreen = [];
						this.msgsScreen.push(ErrorHandler.messageDlgErroNew(error, 'Delete'));
						this.spinnerService.progressSpinnerOff();
					}
				);
			},
		});
	}

	private async isAccessValid() {
		this.spinnerService.progressSpinnerOn();
		this.msgsDlgNewReg = [];
		this.accessValid = false;
		this.isLoading$.emit(true);

		this.validaPermissao = {
			cdsidInclC: localStorage.getItem('codUserPk'),
			codDealer: parseInt(this.dealerCod, 10),
			codUsrC: localStorage.getItem('codUserPk'),
			ipAddressC: this.ipAddress,
			acaoBtn: AppConstants.OPER_ACESSO_A_API,
			token: localStorage.getItem('accessToken')
		};

		await this.service.isAccessValid(this.validaPermissao).then(data => this.accessValid = data);
		this.isLoading$.emit(false);
		if (this.accessValid) {
			this.onRecharge();
			this.spinnerService.progressSpinnerOff();
		} else {
			this.spinnerService.progressSpinnerOff();
			this.router.navigate(['/no-access']);
		}

	}
}
