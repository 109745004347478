import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrudService } from 'src/app/services/crud/crud-service';
import { SelecionaDealer } from './seleciona-dealer.model';

@Injectable({
	providedIn: 'root',
})
export class SelecionaDealerService extends CrudService<SelecionaDealer> {
	
	headers = new HttpHeaders();

	constructor(protected http: HttpClient) {
		super(http, `${environment.apiURL}/api/v1/selecionaDealers/`);
	
		this.headers = this.headers.append( 'Authorization', `Bearer ${localStorage.getItem('accessToken')}`);

	}
}
