
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { tap, take } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

import { ContaDealer, BancoApiDropBox } from './conta-dealer.model';
import { CrudService } from 'src/app/services/crud/crud-service';
import { PageOrder } from '../../../interface/pageOrder/page-order';
import { ValidaPermissao } from 'src/app/interface/valida-permissao';
import { AppConstants } from 'src/app/utils/app-constants';

@Injectable({
	providedIn: 'root',
})
export class ContaDealerService extends CrudService<ContaDealer> {
	
	headers = new HttpHeaders( ) ;//{'Content-Type': 'application/json'});

	constructor(protected http: HttpClient) {
		super(http, `${environment.apiURL}/api/v1/contaDealers/`);
		this.headers = this.headers.append( 'Authorization', `Bearer ${localStorage.getItem('accessToken')}`);
	}


	
	findAllBancoDropBox( validaPermissao: ValidaPermissao) {
		const searchUrl = `${environment.apiURL}/api/v1/bancos/findAllToDropBox?pageNo=0&pageSize=50`;
		
		const httpOptions = { 
			headers: this.headers,
		};
		return this.http
			.post<any>(searchUrl, validaPermissao, { headers: this.headers})
			.pipe(take(1));
	}

	getSearch( validaPermissao: ValidaPermissao, strSearch: string, pageOrder: PageOrder 
		) {
		const searchUrl = `${environment.apiURL}/api/v1/contaDealers/
		findByFavorecidoCNmBcoCNroContaCContainingIgnoreCase?strSearch=${strSearch}&
		pageNo=${pageOrder.getPageNumber}&pageSize=${pageOrder.getPageSize}&
		fieldOrder=${pageOrder.getSortField}&sortOrder=${pageOrder.getSortOrder}`;
		return this.http
			.post<ContaDealer>(searchUrl, validaPermissao, { headers: this.headers})
			.pipe(take(1));
	}

	delete(record: ContaDealer, validaPermissao: ValidaPermissao): Observable<ContaDealer> {
		validaPermissao.acaoBtn = AppConstants.OPER_CRUD_DELETE;
		const request = { payload: record, permission: validaPermissao, id: 0 };
		const header = new HttpHeaders({
			'Content-Type': 'application/json; charset=UTF-8'
		});
		const httpOptions = {
			headers: header,
			body: JSON.stringify({ request })
		};
		const searchUrl = `${environment.apiURL}/api/v1/contaDealers/delete/
		${record.codDealerPk}/${record.codBcoPk}/${record.nroAgenciaC}/${record.nroContaC}`;
		return this.http
			.delete<ContaDealer>(searchUrl, httpOptions)
			.pipe(take(1));
	}

	getFindById(validaPermissao: ValidaPermissao, record: ContaDealer): Observable<ContaDealer> {
		const searchUrl = `${environment.apiURL}/api/v1/contaDealers/
		findById?codDealer=${record.codDealerPk}&
		codBcoPk=${record.codBcoPk}&nroAgenciaC=${record.nroAgenciaC}&nroContaC=${record.nroContaC}`;
		return this.http
			.post<ContaDealer>(searchUrl, validaPermissao, { headers: this.headers})
			.pipe(take(1));
	}
}
