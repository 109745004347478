import { Component, OnInit } from '@angular/core';

@Component({
	//   template: `<p>access works!</p>`
	template: ``
})
export class AccessComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}
}
