import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ProgressSpinnerCtrlService {

	constructor() { }

	private nCout = 0;
	isProgressSpinner$: EventEmitter<boolean> = new EventEmitter();

	progressSpinnerOn() {
		this.incremento();
		this.isProgressSpinner$.emit(true);
	}

	progressSpinnerOff() {
		this.decremento();
		if (this.nCout === 0) {
			this.isProgressSpinner$.emit(false);
		}
	}

	private incremento() {
		this.nCout += 1;
	}

	private decremento() {
		if (this.nCout > 0) {
			this.nCout -= 1;
		}
		if (this.nCout < 0) {
			this.nCout = 0;
		}
	}
}
