import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot
} from '@angular/router';
import { Location } from '@angular/common';
import { LoginService } from 'src/app/oauth/login/login.service';
import { MenuService } from '../../menu/menu.service';
import { Menu } from '../../menu/menu';
import { UserLogin } from 'src/app/oauth/login/login.model';
import { IpService } from '../../ip/ip.service';
import { ValidaPermissao } from 'src/app/interface/valida-permissao';
import { AppConstants } from 'src/app/utils/app-constants';
//import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
//import { HttpClient, HttpHeaders } from '@angular/common/http';

/** This guard is used in the oauth callback flow, consuming the URL before the Angular router can.
 * The URL built by the auth provider is technically invalid (no ? for the queryParams), so this guard
 * needs to consume the URL before Angular's router (which would fail to parse it).
 */
@Injectable()
export class UrlConsumerService implements CanActivate {
	//** Creates an instance of the UrlConsumerService
	//*
	//* @param {route} route instance for current routing params
	//* @param {location} the angular location service for interacting with the browser location object
	//*/
	//headers = new HttpHeaders();
	
	data: UserLogin;
	validaPermissao: ValidaPermissao;

	constructor(private router: Router,
				private location: Location,
				private loginService: LoginService,
				private menuService: MenuService,
				private ipService: IpService
				) { 

		//this.headers = this.headers.append( 'Authorization', `Bearer ${localStorage.getItem('accessToken')}`);

	}

	//** the actual guard fuction. Parses the queryString and stores the params in localStorage.
	// * Redirects the user to the default route, or to the route that was stored before the auth redirect.
	// *
	// * @param {ActivatedRouteSnapshot} childRoute
	// * @param {RouterStateSnapshot} state
	// * @returns {boolean} whether route can be activated or not
	// */
	async canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<boolean> {
		const queryParamsObj = this.getQueryParams();

		// descomentar a linha abaixo quando for para producao
		// descomentar a linha abaixo quando for para producao
		// descomentar a linha abaixo quando for para producao
		// descomentar a linha abaixo quando for para producao
		// descomentar a linha abaixo quando for para producao
		// descomentar a linha abaixo quando for para producao
		this.desableMenu();

		if (queryParamsObj && queryParamsObj['access_token'] != null) {
			//token is part 2 of a JWT (index 1)
			const accessToken = atob(
				queryParamsObj['access_token'].split('.')[1]
			);
			const jsonToken = JSON.parse(accessToken);

			localStorage.clear();

			//localStorage.setItem('strAccessToken', accessToken);
			localStorage.setItem('accessToken', queryParamsObj['access_token']);
			localStorage.setItem('tokenIssue', jsonToken.iat);
			localStorage.setItem('tokenExp', jsonToken.exp);
			//localStorage.setItem('username', jsonToken.CommonName);
			localStorage.setItem('acigroup', jsonToken.ACIGROUP);
			localStorage.setItem('name', jsonToken.givenname);
			localStorage.setItem('last_name', jsonToken.surname);
			localStorage.setItem('email', jsonToken.emailaddress);
			
			localStorage.setItem('codUserPk', jsonToken.userid.toUpperCase() );
			localStorage.setItem('codDn'	, jsonToken.sitecode.toUpperCase().replace('SZ','').replace('BRA','') );

			localStorage.setItem('company', '');
			if ( localStorage.getItem('acigroup').toUpperCase() === 'EMPLOYEE'){
				localStorage.setItem('codDn'	, '0' );
				localStorage.setItem('dealerName', jsonToken.company);
			}

			this.loginService.dealerCod$.emit(localStorage.getItem('codDn'));
			this.loginService.dealerName$.emit(localStorage.getItem('dealerName'));

			//localStorage.setItem('username', JSON.parse(localStorage['accessToken']).CommonName);
			//localStorage.setItem('name', JSON.parse(localStorage['accessToken']).givenname);
			//localStorage.setItem('last_name', JSON.parse(localStorage['accessToken']).surname);
			//localStorage.setItem('email', JSON.parse(localStorage['accessToken']).emailaddress);

			this.loginService.username$.emit(localStorage.getItem('codUserPk'));

			//this.desableMenu();

			//this.getUserGroup();
			
			// obtem dados do usuario x grupo
			this.getSistemaBloqueado(  );

			// obtem o IpAddress
			this.getIp();

			return true;
		} else {
			console.error('Invalid Token');
		}

		return false;
	}

	//** Parses the technically malformed queryString to pick off the token and associated properties.
	// * @returns {Object | null} The queryString params in Object format, or null of the string was invalid.
	// */
	getQueryParams() {
		if (this.location.path(true).indexOf('access_token') === 0) {
			const queryString = this.location.path(true);

			//URLSearchParams should be the solution here. it's not working. so we did it manually
			const paramArray = queryString.split('&');
			const queryParamsObj = new Object();

			for (const param of paramArray) {
				//we can't use a simple split() call here as base64 allows for = padding
				const i = param.indexOf('=');
				if(param && typeof param === 'string') {
				const key = param.slice(0, i);
                const value = param.slice(i + 1);
                if (key && !key.startsWith('__') && key !== '__proto__' && key !== 'prototype') {
                    queryParamsObj[key] = value;
                }
				// 	if(param !== '__proto__'){
				// const splitArray = [param.slice(0, i), param.slice(i + 1)];
				// queryParamsObj1[splitArray[0]] = splitArray[1];
				// 	}
				}
			}

			return queryParamsObj;
		} else {
			return null;
		}
	}

	getIp() {
		this.ipService.getIPAddress().subscribe((res: any) => {
			localStorage.setItem('ipAddress', res.ip);
		});
	}

	private async getSistemaBloqueado() {
		this.validaPermissao = {
			cdsidInclC: localStorage.getItem('codUserPk'),
			codDealer: parseInt(localStorage.getItem('codDn'), 10),
			codUsrC: localStorage.getItem('codUserPk'),
			ipAddressC: localStorage.getItem('ipAddress'),
			acaoBtn: AppConstants.OPER_ACESSO_A_API,
			token: localStorage.getItem('accessToken')
		};
		let sistemaBloqueado = true;
		let  token = localStorage.getItem('accessToken');
		sistemaBloqueado = await this.loginService.getSistemaBloqueadoToken(this.validaPermissao, token).toPromise();
		this.getUserGroup(sistemaBloqueado, token);
	}

	private async getUserGroup(sistemaBloqueado: Boolean, token: string ) {

		this.validaPermissao = {
			cdsidInclC: localStorage.getItem('codUserPk'),
			codDealer: parseInt(localStorage.getItem('codDn'), 10),
			codUsrC: localStorage.getItem('codUserPk'),
			ipAddressC: localStorage.getItem('ipAddress'),
			acaoBtn: AppConstants.OPER_ACESSO_A_API,
			token: localStorage.getItem('accessToken')
		};

		this.data = await this.loginService.getUserToken( this.validaPermissao, token ).toPromise();

		if (this.data.codDn === null) {
			this.desableMenu();
			localStorage.clear;
			this.router.navigate(['/no-access']);
			return;
		}

		localStorage.setItem('grupoC', this.data.grupoC.toUpperCase());
		localStorage.setItem('nomeC', this.data.nomeC);
		localStorage.setItem('emailC', this.data.emailC);
	
		localStorage.setItem('statusC', this.data.statusC);

		// obtem dados do usuario x grupo
		//this.getSistemaBloqueado( );

		// se sistema bloqueado e grupo for "D" - Dealer desabilita os menus , e sistema indisponível
		if ( sistemaBloqueado  && localStorage.getItem('grupoC').toUpperCase() === 'D') {
			this.desableMenu();
			this.router.navigate(['/sistema-indisponivel']);
			return;
		}

		this.loginService.dealerCod$.emit(this.data.codDn.toString());
		this.loginService.dealerDiv$.emit('2');
		this.loginService.dealerName$.emit(this.data.nomeC);

		// se for Dealer grupo = D libera menu com as opções do Dealer
		if (localStorage.getItem('grupoC') === 'D') {
			localStorage.setItem('menu', 'true');
			localStorage.setItem('menuAdm', 'false');
			this.menuService.setMenuSubject(new Menu(true, false));
			this.router.navigate(['/components/conta-corrente']);
		}
		// se for Funcionário grupo = F ou Terceiro grupo = "C" Terceiro grupo = "C"
		// libera menu com as opções do Dealer e o Menu adiministrativo
		if (localStorage.getItem('grupoC') === 'F' || 
		    localStorage.getItem('grupoC') === 'C' || 
			localStorage.getItem('grupoC') === 'T' ) {
			localStorage.setItem('menu', 'true');
			localStorage.setItem('menuAdm', 'true');
			this.menuService.setMenuSubject(new Menu(true, true));
			this.router.navigate(['/access']);
		}

		// se não for Funcionário grupo = "F"  ,  Dealer grupo = "D"  ou Terceiro grupo = "C" acesso negado
		if (localStorage.getItem('grupoC') !== 'F' && 
		    localStorage.getItem('grupoC') !== 'D' && 
			localStorage.getItem('grupoC') !== 'T' && 
			localStorage.getItem('grupoC') !== 'C' ) {
			this.desableMenu();
			this.router.navigate(['/no-access']);
		}
	}

	desableMenu() {
		localStorage.setItem('menu', 'false');
		localStorage.setItem('menuAdm', 'false');
		this.menuService.setMenuSubject(new Menu(false, false));
	}
}
