import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { tap, take } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

import { ControleArquivo } from './controle-arquivo.model';
import { CrudService } from 'src/app/services/crud/crud-service';
import { PageOrder } from '../../../interface/pageOrder/page-order';
import { ValidaPermissao } from 'src/app/interface/valida-permissao';

@Injectable({
	providedIn: 'root',
})
export class ControleArquivoService extends CrudService<ControleArquivo> {

	headers = new HttpHeaders( ); 
	
	constructor(protected http: HttpClient) {
		super(http, `${environment.apiURL}/api/v1/contrArquivos/`);
		
		this.headers = this.headers.append( 'Authorization', `Bearer ${localStorage.getItem('accessToken')}`);
	
	}

	getSearch(validaPermissao: ValidaPermissao, 
		strSearch: string,
		pageOrder: PageOrder
	) {
		const searchUrl = `${environment.apiURL}
		/api/v1/contrArquivos/findByIdNmArquivoCDescArquivoCCabecalhoCRodapeCSequenciaContainingIgnoreCase?strSearch=${strSearch}
		&pageNo=${pageOrder.getPageNumber}&pageSize=${pageOrder.getPageSize}&fieldOrder=${pageOrder.getSortField}
		&sortOrder=${pageOrder.getSortOrder}`;
		return this.http
			.post<ControleArquivo>(searchUrl, validaPermissao,  { headers: this.headers})
			.pipe(take(1));
	}

}
