import { Component, OnInit } from '@angular/core';

@Component({
	template: `<p>{{ "DEFAULT.SISTEMA_INDISPONIVEL" | translate }}</p>`
})
export class SistemaIndisponivelComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
