import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AppConstants } from './utils/app-constants';

export class ErrorHandler {

	static handleError(err: HttpErrorResponse) {
		const errorMessage: string = err.message;

		console.log(`----> Erro codigo: ${err.status} mensagem: ${errorMessage}`);

		return throwError(errorMessage);
	}

	/**********
	*
	*
	*/
	static messageDlgErroNew(error: HttpErrorResponse, origem: string) {
		if (error.status === 404) {
			return { severity: 'warn', summary: '', detail: 'O registro não foi encontrado.', };
		}
		if (error.status === AppConstants.EXPT_CONTRAINT_VIOLATION_EXECEPTION ) {
			return { severity: 'warn', summary: '', detail: 'O Registro ainda tem referência de outra tabela.', };
		}

		if (error.status === 200) {
			return { severity: 'success', summary: '', detail: 'sucesso, ok.', };
		}
		if (error.status === 400) {
			return { severity: 'error', summary: '', detail: 'JSON inválido.', };
		}
		if (error.status === 422) {
			return { severity: 'warn', summary: '', detail: 'Unprocessable Entity: if any of the fields are not parsable or the transaction date is in the future.', };
		}
		if (error.status === 201) {
			return { severity: 'warn', summary: '', detail: 'Created: Everything worked as expected..', };
		}
		if (error.status === 409) {
			return { severity: 'warn', summary: '', detail: 'Conflict: The request conflicts with another request (perhaps due to using the same idempotent key)', };
		}
		if (error.status === 429) {
			return { severity: 'warn', summary: '', detail: 'Too Many Requests: Too many requests hit the API too quickly. We recommend an exponential back-off of your requests', };
		}
		if (error.status === 500) {
			return { severity: 'warn', summary: '', detail: '500 - Server Errors: something went wrong on API end (These are rare)', };
		}
		if (error.status === 502) {
			return { severity: 'warn', summary: '', detail: '502 - Server Errors: something went wrong on API end (These are rare)', };
		}
		if (error.status === 503) {
			return { severity: 'warn', summary: '', detail: '503 - Server Errors: something went wrong on API end (These are rare)', };
		}
		if (error.status === 504) {
			return { severity: 'warn', summary: '', detail: '504 - Server Errors: something went wrong on API end (These are rare)', };
		}
		if (error.status === 302) {
			return { severity: 'warn', summary: '', detail: '302 - O Registro Já Existe', };
		}
		return { severity: 'error', summary: '', detail: 'Erro ao tentar carregar os Registros. Tente novamente mais tarde.', };
	}

	/*
	200: em caso de sucesso.
	400: caso o JSON seja inválido.
	404: caso tentem atualizar um registro que não existe.
	422: se qualquer um dos campos não for parseável (JSON mal formatado).
	•201 - Created: Everything worked as expected.
	•400 - Bad Request: the request was unacceptable, often due to missing a required parameter or invalid JSON.
	•404 - Not Found: The requested resource doesn't exist.
	•409 - Conflict: The request conflicts with another request (perhaps due to using the same idempotent key).
	•422 – Unprocessable Entity: if any of the fields are not parsable or the transaction date is in the future.
	•429 - Too Many Requests: Too many requests hit the API too quickly. We recommend an exponential back-off of your requests.
	•500, 502, 503, 504 - Server Errors: something went wrong on API end (These are rare).

	*/

}
