import { Count } from './../../../interface/count';
import { Component, OnInit } from '@angular/core';
import {
	ConfirmationService,
	SelectItem,
} from 'primeng/api';
import { empty } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ContaFord } from './conta-ford.model';
import { ContaFordService } from './conta-ford.service';
import { PageOrder } from '../../../interface/pageOrder/page-order';
import { ComponentBase } from 'src/app/services/componentBase/component-base';
import { ErrorHandler } from 'src/app/app-error-handler';
import { LoginService } from 'src/app/oauth/login/login.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/utils/app-constants';

interface BancoDropDown {
	codBcoPk: number;
	nroBco: string;
	nmBcoC: string;
}

@Component({
	selector: 'conta-ford',
	templateUrl: './conta-ford.component.html',
	styleUrls: ['./conta-ford.component.scss'],
})
export class ContaFordComponent extends ComponentBase<ContaFord> implements OnInit {

	statusList: SelectItem[];

	bancosDropDown: any[];
	countConta: Count;
	bancoDropDownSelected: BancoDropDown;

	constructor(
		protected service: ContaFordService,
		protected confirmationService: ConfirmationService,
		protected loginService: LoginService,
		protected router: Router,
	) {
		super(service, confirmationService, loginService, router);
	}

	ngOnInit() {

		this.onInit();

		this.sortedField = 'nmRespC';
		this.pageOrder = new PageOrder(0, this.pageSize, this.sortedField, AppConstants.ORDER_INDEX_ASCENDING);
		this.strSearch = '';
		this.theTotalElements = 0;
		this.displayDialog = false;
		this.colsTab = [
			{ field: 'nroBco'		, index: 'bancoBO.nroBco'       		, header: 'BANCO.NRO_BANCO', mask: '', indexed: 'false', alignText: 'center' },
			{ field: 'nmBcoC'		, index: 'bancoBO.nmBcoC'        	, header: 'BANCO.NM_BANCO', mask: '', indexed: 'false', alignText: 'center' },
			{ field: 'nroAgenciaC'	, index: 'contaFordBOPK.nroAgenciaC'	, header: 'BANCO_COD_AGENCIA', mask: '', indexed: 'false', alignText: 'center' },
			{ field: 'nroContaC'	, index: 'contaFordBOPK.nroContaC'	, header: 'BANCO.NRO_CONTA', mask: '', indexed: 'false', alignText: 'center' },
			{ field: 'nmRespC'		, index: ''       					, header: 'CTA_FORD.RESPONSAVEL', mask: '', indexed: 'true', alignText: 'left' },
			{ field: 'statusDescC'	, index: 'statusC'					, header: 'DEFAULT.ATIVO', mask: '', indexed: 'true', alignText: 'center' },
		];

		this.clearRegData();

		this.clearNewRegData();

		this.statusList = [
			{ label: 'Selecione', value: '' },
			{ label: 'Ativo', value: 'S' },
			{ label: 'Inativo', value: 'N' },
		];

		this.loadBancos();

	}

	clearNewRegData() {
		this.newRegData = {
			codBcoPk: null,
			nroBco: null,
			nmBcoC: null,
			nroAgenciaC: null,
			nroContaC: null,
			nmRespC: null,
			statusC: 'N',
			statusDescC: null,
			cdsidIncl: null,
			dtIncl: null,
			cdsidUltAlt: null,
			dtUltAlt: null,
		};

		this.bancoDropDownSelected = {
			codBcoPk: null,
			nroBco: null,
			nmBcoC: null,
		};
	}

	processloadRowSelect() {
		return (data) => {
			this.newRegData = data;
			this.bancoDropDownSelected = this.regToDropDown(this.newRegData);
			this.displayDialog = true;
			this.spinnerService.progressSpinnerOff();
		};
	}

	regToDropDown(newRegData: ContaFord): any {
		let bancoTmp: BancoDropDown;
		bancoTmp = {
			codBcoPk: null,
			nroBco: null,
			nmBcoC: null,
		};
		bancoTmp.codBcoPk = newRegData.codBcoPk;
		bancoTmp.nroBco = newRegData.nroBco;
		bancoTmp.nmBcoC = newRegData.nmBcoC;
		return bancoTmp;
	}

	onChangeDrop(evento) {
		this.newRegData.codBcoPk = evento.value.codBcoPk;
		this.newRegData.nroBco = evento.value.nroBco;
		this.newRegData.nmBcoC = evento.value.nmBcoC;
		this.newRegData.nroAgenciaC = '';
		this.newRegData.nroContaC = '';
	}

	loadBancos() {
		this.validaPermissao = {
			cdsidInclC: localStorage.getItem('codUserPk'),
			codDealer: parseInt(localStorage.getItem('codDn'), 10),
			codUsrC: localStorage.getItem('codUserPk'),
			ipAddressC: localStorage.getItem('ipAddress'),
			acaoBtn: AppConstants.OPER_ACESSO_A_API,
			token: localStorage.getItem('accessToken')
		};
		this.spinnerService.progressSpinnerOn();
		this.service
			.findAllBancoDropBox(this.validaPermissao)
			.pipe(
				catchError(error => {
					this.spinnerService.progressSpinnerOff();
					this.msgsDlgNewReg = [];
					this.msgsDlgNewReg.push(ErrorHandler.messageDlgErroNew(error, 'loadBancos'));
					ErrorHandler.handleError(error);
					return empty();
				})
			)
			.subscribe((data) => {
				this.bancosDropDown = data.content;
				this.spinnerService.progressSpinnerOff();
			});
	}

	onChangeStatus(evento) {
		this.validaPermissao = {
			cdsidInclC: localStorage.getItem('codUserPk'),
			codDealer: parseInt(localStorage.getItem('codDn'), 10),
			codUsrC: localStorage.getItem('codUserPk'),
			ipAddressC: localStorage.getItem('ipAddress'),
			acaoBtn: AppConstants.OPER_ACESSO_A_API,
			token: localStorage.getItem('accessToken')
		};
		this.spinnerService.progressSpinnerOn();
		this.service
			.findIsCtaAtiva(this.validaPermissao)
			.pipe(
				catchError(error => {
					this.spinnerService.progressSpinnerOff();
					this.msgsDlgNewReg = [];
					this.msgsDlgNewReg.push(ErrorHandler.messageDlgErroNew(error, 'onChangeStatus'));
					ErrorHandler.handleError(error);
					return empty();
				})
			)
			.subscribe((data) => {
				if (data.isActive && this.newRegData.statusC === 'S') {
					evento = 'N';
					this.processChageStatus(data);
				}
				this.countConta = data;
				this.spinnerService.progressSpinnerOff();
			});
	}

	processChageStatus(count: Count) {
		this.newRegData.statusC = 'N';
		this.msgsScreen = [];
		this.msgsScreen.push({
			severity: 'error',
			summary: '',
			detail: 'Só pode existir uma conta ativa'
		});
	}
}
