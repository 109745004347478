import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { tap, take } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

import { GrupoDealer } from './grupo-dealer.model';
import { CrudService } from 'src/app/services/crud/crud-service';
import { PageOrder } from '../../../interface/pageOrder/page-order';
import { ValidaPermissao } from 'src/app/interface/valida-permissao';
import { AppConstants } from 'src/app/utils/app-constants';

@Injectable({
	providedIn: 'root',
})
export class GrupoDealerService extends CrudService<GrupoDealer> {
	
	headers = new HttpHeaders();

		constructor(protected http: HttpClient) {
		super(http, `${environment.apiURL}/api/v1/grupoDealers/`);

		this.headers = this.headers.append( 'Authorization', `Bearer ${localStorage.getItem('accessToken')}`);

	}

	getSearch(validaPermissao: ValidaPermissao, 
		strSearch: string,
		pageOrder: PageOrder
	) {
		const searchUrl = `${environment.apiURL}
		/api/v1/grupoDealers/findByGrupoDealerNameGrupoNmGrpCContainingIgnoreCase?
		strSearch=${strSearch}&pageNo=${pageOrder.getPageNumber}&
		pageSize=${pageOrder.getPageSize}&fieldOrder=${pageOrder.getSortField}&
		sortOrder=${pageOrder.getSortOrder}`;
		return this.http.post<GrupoDealer>(searchUrl, validaPermissao, { headers: this.headers})
						.pipe(take(1));
	}

	delete(record: GrupoDealer, validaPermissao: ValidaPermissao): Observable<GrupoDealer> {
		validaPermissao.acaoBtn = AppConstants.OPER_CRUD_DELETE;
		const request = { payload: record, permission: validaPermissao, id: 0 };
		const header = new HttpHeaders({
			'Content-Type': 'application/json; charset=UTF-8',
			'Authorization' : `Bearer ${localStorage.getItem('accessToken')}`
		});
		const httpOptions = {
			headers: header,
			body: JSON.stringify({ request })
		};
		const searchUrl = `${environment.apiURL}/api/v1/grupoDealers/delete/${record.grpIdPk}/${record.codDealerPk}`;
		return this.http.delete<GrupoDealer>(searchUrl, httpOptions)
						.pipe(take(1));
	}

	getFindById(validaPermissao: ValidaPermissao, record: GrupoDealer) {
		const searchUrl = `${environment.apiURL}/api/v1/grupoDealers/
		findById?grpId=${record.grpIdPk}&codDealer=${record.codDealerPk}`;
		return this.http.post<GrupoDealer>(searchUrl, validaPermissao, { headers: this.headers})
						.pipe(take(1));
	}

}
