// import { LocaleSettings } from 'primeng/calendar';
import { Injectable } from '@angular/core';
import en from '@angular/common/locales/en';
import es from '@angular/common/locales/es';
import pt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class LocaleService {

	constructor() { }

	private _locale: string;

	// getLocale() {
	// 	return 'pt-BR';
	// }
	getLocale() {
		return this.locale;
	}

	set locale(value: string) {
		this._locale = value;
	}
	get locale(): string {
		return this._locale || 'pt-BR';
	}

	registerCulture(culture: string) {
		if (!culture) {
			return;
		}
		this.locale = culture;
		switch (culture) {
			case 'en': {
				registerLocaleData(en, 'en');
				break;
			}
			case 'es': {
				registerLocaleData(es, 'es');
				break;
			}
			case 'pt': {
				registerLocaleData(pt, 'pt-BR');
				break;
			}

		}

	}
}
