import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ValidaPermissao } from 'src/app/interface/valida-permissao';
import { take } from 'rxjs/internal/operators/take';

@Injectable({
	providedIn: 'root'
})
export class BaseService{

	headers = new HttpHeaders();

	constructor(protected http: HttpClient, protected baseUrl: string) { 
		this.headers = this.headers.append( 'Authorization', `Bearer ${localStorage.getItem('accessToken')}`);
		 
	}
	
	isAccessValid(validaPermissao: ValidaPermissao): Promise<any> {
		const searchUrl = this.baseUrl + `isAccessValid`;
		return this.http.put(searchUrl, validaPermissao, { headers: this.headers} )
						.toPromise()
						.then(data => {return data;});
	}

	getDealerCheck(validaPermissao: ValidaPermissao, codDealer: number, dtHrStrMovto: string): Promise<any> {
		const searchUrl = this.baseUrl + `dealerCheck?codDealer=${codDealer}&dtMovto=${dtHrStrMovto}`;
		return this.http.post(searchUrl, validaPermissao, { headers: this.headers})
						.toPromise()
						.then(data => {return data;});
	}

}
