import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';

import { Dealer } from './dealer.model';
import { CrudService } from 'src/app/services/crud/crud-service';
import { PageOrder } from '../../../interface/pageOrder/page-order';
import { ValidaPermissao } from 'src/app/interface/valida-permissao';

@Injectable({
	providedIn: 'root',
})
export class DealerService extends CrudService<Dealer> {
	
	headers = new HttpHeaders( ); 
	
	constructor(protected http: HttpClient) {
		super(http, `${environment.apiURL}/api/v1/dealers/`);
		
		this.headers = this.headers.append( 'Authorization', `Bearer ${localStorage.getItem('accessToken')}`);

	}

	getSearch(validaPermissao: ValidaPermissao,strSearch: string,pageOrder: PageOrder
	) {
		const searchUrl = `${environment.apiURL}/api/v1/dealers/findByDealerNameCEmailCCodDealerPkMatrizCodeContainingIgnoreCase?strSearch=
							${strSearch}&pageNo=${pageOrder.getPageNumber}&pageSize=${pageOrder.getPageSize}&
							fieldOrder=${pageOrder.getSortField}&sortOrder=${pageOrder.getSortOrder}`;
		return this.http.post<Dealer>(searchUrl, validaPermissao, { headers: this.headers})
						.pipe(take(1));
	}

	getSearchSelecaoDealer(validaPermissao: ValidaPermissao,strSearch: string,pageOrder: PageOrder
	) {
		const searchUrl = `${environment.apiURL}/api/v1/dealers/findByDealerNameCCodDealerPkMatrizCodeContainingIgnoreCase?strSearch=
							${strSearch}&pageNo=${pageOrder.getPageNumber}&pageSize=${pageOrder.getPageSize}&
							fieldOrder=${pageOrder.getSortField}&sortOrder=${pageOrder.getSortOrder}`;
		return this.http.post<Dealer>(searchUrl,  validaPermissao, { headers: this.headers})
						.pipe(take(1));
	}

	getSearchStatus(validaPermissao: ValidaPermissao,strSearch: string,status: string,
		pageOrder: PageOrder
	) {
		const searchUrl = `${environment.apiURL}/api/v1/dealers/findByDealerNameCEmailCCodDealerPkMatrizCodeContainingIgnoreCaseStatus?strSearch=${strSearch}
							&status=${status}
							&pageNo=${pageOrder.getPageNumber}&pageSize=${pageOrder.getPageSize}&
							fieldOrder=${pageOrder.getSortField}&sortOrder=${pageOrder.getSortOrder}`;
		return this.http.post<Dealer>(searchUrl,  validaPermissao, { headers: this.headers})
						.pipe(take(1));
	}

	getAllStatus(validaPermissao: ValidaPermissao, status: string, pageOrder: PageOrder ) {
		const searchUrl = `${environment.apiURL}/api/v1/dealers/findByStatus?&status=${status}
							&pageNo=${pageOrder.getPageNumber}&pageSize=${pageOrder.getPageSize}
							&fieldOrder=${pageOrder.getSortField}&sortOrder=${pageOrder.getSortOrder}`;
		return this.http.post<Dealer>(searchUrl,  validaPermissao, { headers: this.headers})
						.pipe(take(1));
	}

	// getAllPageOrder( validaPermissao: ValidaPermissao, pageOrder: PageOrder ) {
	// 	const searchUrl = `${environment.apiURL}/api/v1/dealers/findAll?pageNo=${pageOrder.getPageNumber}&pageSize=${pageOrder.getPageSize}
	// 						&fieldOrder=${pageOrder.getSortField}&sortOrder=${pageOrder.getSortOrder}`;
	// 	return this.http.post<Dealer>(searchUrl,  validaPermissao, { headers: this.headers})
	// 			.pipe(take(1));
	// }

	getFindById(validaPermissao: ValidaPermissao, record: Dealer) {
		const searchUrl = `${environment.apiURL}/api/v1/dealers/findById?codDealer=${record.codDealerPk}`;
		return this.http.post<Dealer>(searchUrl,  validaPermissao, { headers: this.headers})
						.pipe(take(1));
	}

	delete( record: Dealer): any {

	}

	save( record: Dealer, recNew: boolean): any {

	}

	getSearchStatusSelecaoDealer(
		validaPermissao: ValidaPermissao, 
		strSearch: string,
		status: string,
		pageOrder: PageOrder
	) {
		const searchUrl = `${environment.apiURL}/api/v1/dealers/findByDealerNameCCodDealerPkMatrizCodeContainingIgnoreCaseStatus?strSearch=${strSearch}
							&status=${status}
							&pageNo=${pageOrder.getPageNumber}&pageSize=${pageOrder.getPageSize}&
							fieldOrder=${pageOrder.getSortField}&sortOrder=${pageOrder.getSortOrder}`;
		return this.http.post<Dealer>(searchUrl, validaPermissao,  { headers: this.headers})
						.pipe(take(1));
	}
}
