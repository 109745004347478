import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class AppConstants {

	constructor() { }

	public static get TP_LANCAMENTOS_TODOS(): number { return 0; }
	public static get TP_LANCAMENTOS_ENTRADAS(): number { return 1; }
	public static get TP_LANCAMENTOS_SAIDAS(): number { return 2; }

	public static get NRO_DIAS_INICIAL(): number { return 7; }
	public static get NRO_DIAS_INICIAL_CONSULTA(): number { return 1; }
	public static get PERIODO_PERMITIDO_CONSULTA(): number { return 30; }
	public static get PERIODO_PERMITIDO_EXTRATO(): number { return 30; }

	public static get PERIODO_MAXIMO_PERMITIDO_CONSULTA(): number { return 31; }
	public static get PERIODO_MAXIMO_PERMITIDO_EXTRATO(): number { return 90; }

	public static get OPC_LANCAMENTOS(): number { return 0; }
	public static get OPC_PAGAMENTOS(): number { return 1; }

	public static get TP_PAGAMENTOS_FAV(): number { return 0; }
	public static get TP_TRANSFERENCIA(): number { return 1; }
	public static get TP_RESGATES(): number { return 2; }

	// operacoes
	public static get OPER_ACESSO_A_API(): number { return 0; }
	public static get OPER_ADICIONA_TRANSFER_FILIAIS(): number { return 1; }
	public static get OPER_ADICIONA_PAGAMENTOS_FAV(): number { return 2; }
	public static get OPER_ADICIONA_RESGATES(): number { return 3; }

	// operacoes / crud
	public static get OPER_CRUD_INSERT(): number { return 10; }
	public static get OPER_CRUD_UPDATE(): number { return 11; }
	public static get OPER_CRUD_DELETE(): number { return 12; }
	public static get OPER_CRUD_LOAD(): number { return 13; }

	// order
	public static get ORDER_INDEX_ASCENDING(): number { return 1; }
	public static get ORDER_INDEX_DESCENDING(): number { return -1; }

	// exception
	public static get EXPT_CONTRAINT_VIOLATION_EXECEPTION(): number { return 499; }

	// DropDown
	public static get DROPDOWN_DEALERS_SEM_OPC_TODOS(): number { return 0; }
	public static get DROPDOWN_DEALERS_COM_OPC_TODOS(): number { return 1; }

}
